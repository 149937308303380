import React, { useState, useRef } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import SmallImg from "../../../components/Image/SmallImg"

import {
  Title,
  SubTitle,
  Input,
  Button,
  Field,
  Control,
  Columns,
  Column,
  StaticButton,
} from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Calculator", link: "/calculator/" },
  {
    name: "Loan Calculator",
    link: "/mortgage-loan-calculator/",
  },
]
const seeAlsoArray = [
  "/date-and-time-calculator",
  "/fuel-consumption-calculator",
  "/kite-size-calculator",
  "/percentage-calculator",
  "/rep-max-calculator",
  "/shopping-calculator",
  "/tax-cost-calculator",
  "/tip-calculator",
]

function MortgageLoanCalculator(props) {
  let [ResultPayment, setResultPayment] = useState("")
  let loanAmount = useRef("")
  let interestRate = useRef("")
  let years = useRef("")

  function calculatePayment(e) {
    var myyears = years.current.value
    var myinterestRate = interestRate.current.value
    var XloanAmount = loanAmount.current.value

    var XXinterestRate = interestRate.current.value / 1200
    var XXyears = years.current.value * 12

    var payment =
      XloanAmount *
      (XXinterestRate / (1 - Math.pow(1 + XXinterestRate, -XXyears)))
    var totalInterest = payment * XXyears - XloanAmount
    var totalLoan = parseInt(XloanAmount) + totalInterest

    if (XloanAmount === "" || isNaN(XloanAmount)) {
      setResultPayment(<strong>Please Enter Loan Amount !</strong>)
    } else if (myinterestRate === "" || isNaN(myinterestRate)) {
      setResultPayment(<strong>Please Enter Interest Rate (%) !</strong>)
    } else if (myyears === "" || isNaN(myyears)) {
      setResultPayment(
        <strong>Please Enter time period of the loan by Years !</strong>
      )
    } else {
      setResultPayment(
        <p>
          Your Monthly Payment =
          {payment.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
          <br />
          Interest Paid on Loan ={" "}
          {totalInterest.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
          <br />
          Total Loan Cost ={" "}
          {totalLoan.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
      )
    }
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Mortgage Loan Calculator"
        description="Mortgage Loan Calculator, Loan Calculation, Calculate Your Monthly Payment of Loan, How many Your Interest Paid on Loan, and the Total Loan Coste."
        keywords={[
          "Calculate Your Monthly Payment of Loan, calculate Interest Paid on Loan, calculate Total Loan Cost, loan calculation, mortgage loan calculator, loan calculator total cost, Interest Paid calculator.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Loan Calculator</Title>
        <SubTitle>Calculate Loan</SubTitle>
        <Columns>
          <Column>
            <Field>
              <Control>
                <StaticButton>Loan Amount</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input radius="0" type="number" ref={loanAmount} />
              </Control>
              <Control>
                <StaticButton
                  bottomLeftRadius="0"
                  TopLeftRadius="0"
                  bottomRightRadius="4px"
                  topRightRadius="4px"
                >
                  $
                </StaticButton>
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Interest Rate</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input radius="0" type="number" ref={interestRate} />
              </Control>
              <Control>
                <StaticButton
                  bottomLeftRadius="0"
                  TopLeftRadius="0"
                  bottomRightRadius="4px"
                  topRightRadius="4px"
                >
                  %
                </StaticButton>
              </Control>
            </Field>
            <Field>
              <Control>
                <StaticButton>How many Years</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input radius="0" type="number" ref={years} />
              </Control>
              <Control>
                <StaticButton
                  bottomLeftRadius="0"
                  TopLeftRadius="0"
                  bottomRightRadius="4px"
                  topRightRadius="4px"
                >
                  years
                </StaticButton>
              </Control>
            </Field>

            <Button
              type="button"
              className="mb-2"
              borderColor="#1da1f2"
              color="#1da1f2"
              hoverColor="white"
              hoverBorderColor="#1da1f2"
              hoverBackgroundColor="#1da1f2"
              onClick={calculatePayment}
            >
              Calculate
            </Button>

            <div>{ResultPayment}</div>
          </Column>
          <Column>
            <FlexDiv maxWidth="250px" margin="auto">
              <SmallImg filename="loan.png" alt="kelvin unit converter" />
            </FlexDiv>
          </Column>
        </Columns>
        <br />
        <h3>Loan Calculator</h3>
        <p>
          Calculate Your Monthly Payment of Loan, How many Your Interest Paid on
          Loan, and the Total Loan Cost.
          <br />
          Loan calculators are tools that enable users to determine their
          monthly payment, total interest paid on the loan, and total loan cost.
          Just by adding loan amount, interest rate, and how many years.
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default MortgageLoanCalculator
